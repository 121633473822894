/**
 * Pops up a printable item barcode in a new window
 * @param itemID
 * @param yourLabel
 */
export function showBarcodePopUp(itemID: string, yourLabel?: string) {
    let customLabel = '';
    if (yourLabel) {
        customLabel = `&xitem=${encodeURI(yourLabel)}`;
    }
    const url = `/printable-barcode?item=${itemID}${customLabel}`;
    const desiredWidth = 280;
    const desiredHeight = 150;
    const leftPosition = screen.width ? (screen.width - desiredWidth) / 2 : 0;
    const topPosition = screen.height ? (screen.height - desiredHeight) / 2 : 0;
    const settings = `height=${desiredHeight},width=${desiredWidth},top=${topPosition},left=${leftPosition},toolbar=0,location=0,directories=0,status=1,menubar=0,scrollbars=1,resizable=1`;
    window.open(url, `win`, settings);
}
